import { useEffect, useRef } from "react";
// eslint-disable-next-line import/no-unresolved
import { useIgTestGroup } from "@intelligems/headless/gatsby";
import { useTracking } from "../context/Tracking";
import { useAddToCartWithLuxe } from "../context/LuxeProvider";

import { isBrowser } from "../context/helpers";

function transformProductData(jsonData) {
  // Group products by ID, counting occurrences
  const groupedProducts = jsonData.productsForATC.reduce((acc, product) => {
    // Find if the product already exists in the accumulator
    const variantId = `gid://shopify/ProductVariant/${product.id}`;
    const existingProductIndex = acc.findIndex((item) => item.variant.id === variantId);
    if (existingProductIndex === -1) {
      // If product doesn't exist, add it with quantity 1
      acc.push({
        quantity: 1,
        attributes: [{ key: "vto", value: "true" }],
        variant: {
          id: variantId,
          sku: product.sku,
          price: product.price,
          product: {
            title: product.name,
            id: product.id,
            handle: null,
            productType: null,
            images: null,
          },
        },
      });
    } else {
      // If product exists, increment its quantity
      acc[existingProductIndex].quantity += 1;
      acc[existingProductIndex].variant.price += product.price;
    }
    return acc;
  }, []);

  return groupedProducts;
}

function TangibleeListener() {
  const { trackVtoATC } = useTracking();
  const addToCartWithLuxe = useAddToCartWithLuxe();
  const tangibleeTest = useIgTestGroup("0cf336d9-a725-48b5-9e97-744b47452ade");
  const tangibleeName = tangibleeTest?.testGroup?.name;

  const cartEventListenerAdded = useRef(false);

  useEffect(() => {
    if (isBrowser) {
      const handleTangibleeActivate = () => {
        if (window.CustomEvent && tangibleeName) {
          const event = new CustomEvent("tangiblee-triggered", {
            detail: { variation: tangibleeName },
          });
          window.dispatchEvent(event);
          window.tngVariant = tangibleeName;
        }
      };

      // Cart Add Event Listener
      const handleAddToCart = async (event) => {
        console.log("Add to cart event triggered", event);
        try {
          // Log the product details being added to cart
          if (event.detail) {
            const dataProductsToAdd = transformProductData(event.detail);
            console.log(dataProductsToAdd, "=> dataProductsToAdd");
            trackVtoATC(dataProductsToAdd);
            await addToCartWithLuxe({ dataProductsToAdd });
          }
        } catch (error) {
          console.error("Error adding product to cart:", error);
        }
      };

      // Add event listener when component mounts
      window.addEventListener("tangiblee-activate", handleTangibleeActivate);

      // Add Cart event listener
      if (!cartEventListenerAdded.current) {
        window.addEventListener("tng-add-to-cart", handleAddToCart);
        cartEventListenerAdded.current = true;

        // Remove the event listener if it's already been added
        return () => {
          window.removeEventListener("tangiblee-activate", handleTangibleeActivate);
          window.removeEventListener("tng-add-to-cart", handleAddToCart);
          cartEventListenerAdded.current = false;
        };
      }

      // Clean up event listener when component unmounts
      return () => {
        window.removeEventListener("tangiblee-activate", handleTangibleeActivate);
      };
    }
  }, [tangibleeName, addToCartWithLuxe, trackVtoATC]); // Empty dependency array means this runs once on mount

  return null; // This component doesn't render anything visible
}

export default TangibleeListener;
