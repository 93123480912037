import PropTypes from "prop-types";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import axios from "axios";
// eslint-disable-next-line import/no-unresolved
import { useIgCart } from "@intelligems/headless/gatsby";

import { GeoContext } from "./GeoProvider";
import { useTracking } from "./Tracking";
import { useALError } from "../helpers/ErrorBoundary/ALErrorBoundary";
import {
  decodeProductId,
  encryptData,
  getShopifyObjectId,
  isBrowser,
  getAllUrlParams,
  addURLParam,
  removeQueryParam,
  deleteCookie,
  getCookie,
  getShopifyCartIdStorageKey,
  normalizeLines,
} from "./helpers";
import useDiscountsInfo from "../hooks/useDiscountsInfo";
import StoreContext from "./store";
import DISCOUNT_TYPES from "../constants/DiscountTypes";
import { HandleType } from "../constants/HandleType";
import REDIRECT_TYPES from "../constants/RedirectType";
import { CART_UPDATE_EVENT_NAME } from "./blotoutShopWallet";

export const defaultCartObject = {
  lines: [],
  discountCodes: [],
};
const defaultCartContext = {
  cart: defaultCartObject,
  isLoadingCart: false,
};

const EXCLUDE_ITEMS_TAGS = [
  "GIVES BACK",
  "Type:Accessories",
  "bundle",
  "mystery",
  "giftcard",
  "subscription",
  "Type:Earring Charms",
];

export const CartContext = createContext(defaultCartContext);

function buildAttributes(lines = []) {
  return lines.map((line) => {
    const { attributesProperties } = line;
    if (attributesProperties) {
      // Pre-order
      if (attributesProperties.isPreOrder) {
        line.attributes.push(
          { key: "_pre_order", value: "true" },
          {
            key: "_ships_by_pre_order",
            value: attributesProperties.preOrderShipDate,
          }
        );
      }

      // Engravable
      if (attributesProperties.isEngravable && attributesProperties.engraveDetails) {
        line.attributes.push(
          { key: "_engravable", value: "true" },
          { key: "_engravable_font", value: attributesProperties.engraveDetails.font },
          { key: "_engravable_text", value: attributesProperties.engraveDetails.text },
          { key: "_line_item_barcode", value: Date.now().toString() }
        );
      }

      // Prices info
      if (
        attributesProperties.isUS &&
        attributesProperties.prices &&
        "finalPrice" in attributesProperties.prices &&
        "fullPrice" in attributesProperties.prices
      ) {
        line.attributes.push(
          { key: "_customer_currency", value: attributesProperties?.currencyCode || "" },
          {
            key: "_customer_discount_value",
            value:
              (
                parseFloat(attributesProperties.prices?.fullPrice) -
                parseFloat(attributesProperties.prices?.finalPrice)
              ).toString() || "",
          }
        );
      }

      // RoW Prices info
      if (!attributesProperties.isUS) {
        line.attributes.push(
          {
            key: "_compare_at_price",
            value: attributesProperties.prices?.fullPrice?.toString() || "",
          },
          { key: "_price", value: attributesProperties.prices?.finalPrice?.toString() || "" },
          { key: "_discount_code", value: attributesProperties.discountsInfo?.[0]?.code || "" }
        );
      }

      // Final Sale tag
      if (attributesProperties.isFinalSale) {
        line.attributes.push({ key: "_final_sale", value: "true" });
      }

      // IG Prices
      if (attributesProperties.igPrices?.isIgPrice) {
        line.attributes.push(
          {
            key: "_ig_full_price",
            value: attributesProperties.fullPrice,
          },
          {
            key: "_ig_final_price",
            value: attributesProperties.finalPrice,
          },
          {
            key: "_ig_compareAt_price",
            value: attributesProperties.compareAtPrice,
          }
        );
      }

      // Impact Radius
      const impactClickId = getCookie("_irclickid");
      if (impactClickId) {
        line.attributes.push({ key: "_irclickid", value: impactClickId });
      }
    }
    return line;
  });
}

function encryptLinesAttributes(lines) {
  const ATTRIBUTES_TO_ENCODE = ["customer_discount_value", "price", "compare_at_price"];

  return lines.map(({ attributes, ...line }) => ({
    ...line,
    attributes: attributes.map(({ key, value }) =>
      ATTRIBUTES_TO_ENCODE.includes(key)
        ? {
            key: encryptData(key),
            value: encryptData(value),
            type: "enc",
          }
        : { key, value }
    ),
  }));
}

function setCartId(cartLocalStorageKey, cartId, sendReport = () => {}) {
  if (isBrowser) {
    localStorage.setItem(cartLocalStorageKey, cartId);

    // Track cart event - help CRM to retrieve cart
    if (window.exponea) {
      window.exponea.track("track_cart", {
        cartId: encodeURIComponent(cartId),
      });
    }

    // Shopify cookie used for checkout.analuisa.com/checkout redirection
    const expiration = new Date();
    expiration.setHours(expiration.getHours() + 4); // Shopify seems to set their cookie to expire in 4 hours
    document.cookie = `cart=${cartId.replace(
      "gid://shopify/Cart/",
      ""
    )};expires=${expiration.toISOString()};path=/;domain=analuisa.com`;

    try {
      // /!\ Do not remove, this is used for avoid crashes on old versions of the cart
      document.cookie.split("; ").forEach((cookie) => {
        const [key] = cookie.split("=");
        if (key.startsWith("gid://shopify/Cart")) {
          deleteCookie(key);
        }
      });
    } catch (error) {
      sendReport(error, { name: "CartProvider", priority: "P1" });
    }
  }
}

function prepareCartForAddToCartExponea(cart) {
  const productIds = [];
  const productList = [];
  const skuIds = [];
  const skuList = [];
  const variantIds = [];
  const variantList = [];
  const { lines } = cart;
  let totalPrice = 0;
  let totalPriceLocalCurrency = 0;
  let totalQuantity = 0;

  if (lines && lines.length > 0) {
    lines.forEach((p) => {
      const productId = decodeProductId(p.variant.product.id).replace("gid://shopify/Product/", "");
      const variantId = decodeProductId(p.variant.id).replace("gid://shopify/Product/", "");
      const qty = p.quantity;
      totalQuantity += qty;
      productList.push({
        product_id: productId,
        quantity: qty,
      });
      skuList.push({
        quantity: qty,
        sku: p.variant.sku,
      });
      variantList.push({
        quantity: qty,
        variant_id: variantId,
      });

      totalPrice += parseFloat(p.variant.price.amount);
      totalPriceLocalCurrency += parseFloat(p.variant.price.amount);

      skuIds.push(p.variant.sku);
      productIds.push(productId);
      variantIds.push(variantId);
    });
  }

  // productIds: [6832288727239, 6804546126023, 4684363235402]
  // productList: [{"product_id":"6832288727239","quantity":1}, {"product_id":"6804546126023","quantity":2}, {"product_id":"4684363235402","quantity":1}]
  // skuIds: [C-191315, 310001-151-20-1, 100020-710-01-2]
  // skuList: [{"quantity":1,"sku":"C-191315"}, {"quantity":1,"sku":"310001-151-20-1"}, {"quantity":2,"sku":"100020-710-01-2"}]
  // totalPrice: 303
  // totalPriceLocalCurrency: 303
  // totalQuantity: 4
  // variantIds: [40357760401607, 40256421200071, 32337754619978]
  // variantList: [{"quantity":1,"variant_id":"40357760401607"}, {"quantity":2,"variant_id":"40256421200071"}, {"quantity":1,"variant_id":"32337754619978"}]

  return {
    product_ids: productIds,
    product_list: productList,
    sku_ids: skuIds,
    sku_list: skuList,
    variant_ids: variantIds,
    variant_list: variantList,
    total_price: totalPrice,
    total_price_local_currency: totalPriceLocalCurrency,
    total_quantity: totalQuantity,
  };
}

function CartProvider({ children }) {
  const location = useLocation();
  const { sendReport } = useALError();
  const { trackAddToCart, trackRemoveFromCart, trackAddToCartExponea } = useTracking();
  const { gePriceDetails, clientIp } = useContext(GeoContext);
  const discountsInfo = useDiscountsInfo();
  const { searchIndex } = useContext(StoreContext);

  const [cart, setCart] = useState(defaultCartObject);
  const [isLoadingCart, setIsLoadingCart] = useState(false);
  const [isSideCartOpen, setIsSideCartOpen] = useState(false);

  const countryCode = gePriceDetails?.CountryCode;
  const currencyCode = gePriceDetails?.CurrencyCode;

  const { wrapCustomAttributes } = useIgCart(cart?.cartId);
  const params = useMemo(() => getAllUrlParams(location.href), [location.href]);

  useEffect(() => {
    // Open cart from cart params
    if (isBrowser && params?.cart) {
      setIsSideCartOpen(true);
    }
    // Replace the URL with the checkout URL
    if (params?.redirect === REDIRECT_TYPES.GO_TO_CHECKOUT && cart.checkoutUrl) {
      removeQueryParam("redirect");
      addURLParam("redirect", cart.checkoutUrl);
      navigate(window.location.href.split(window.location.origin)[1]);
    }
  }, [cart.checkoutUrl, params]);

  useEffect(() => {
    // when the sidecart is open, add a "cart=true" query param to the URL
    if (isBrowser && isSideCartOpen && !params?.cart) {
      addURLParam("cart", "true");
    } else {
      removeQueryParam("cart");
    }
  }, [isSideCartOpen, params?.cart]);

  const toggleCart = useCallback(() => {
    setIsSideCartOpen((prev) => !prev);
  }, []);

  const quantityPercentDiscount = discountsInfo.find(
    (d) => d.type === DISCOUNT_TYPES.QUANTITY_PERCENT
  );
  const discountCode = useMemo(() => {
    if (!!quantityPercentDiscount && !quantityPercentDiscount?.shouldCodeBeApplied) {
      return null; // Tier discount should not have code in cart
    }
    return discountsInfo?.[0]?.code;
  }, [discountsInfo, quantityPercentDiscount]);

  const cartLocalStorageKey = useMemo(() => getShopifyCartIdStorageKey(countryCode), [countryCode]);

  const retrieveCart = useCallback(async () => {
    const existingCartId = isBrowser ? localStorage.getItem(cartLocalStorageKey) : null;
    try {
      setIsLoadingCart(true);
      const res = await axios.get("/.netlify/functions/get-cart", {
        params: {
          cartId: existingCartId,
          countryCode,
          discountCode,
        },
        headers: { ip_address: clientIp }, // allow clientIp to not be filled for perf purposes
      });
      if (res.status !== 200) {
        throw new Error(`Received invalid response status fro get-cart (status: ${res.status})`);
      }
      setCart(res.data);
      setCartId(cartLocalStorageKey, res.data.cartId, sendReport);
    } catch (error) {
      console.error("Network HTTP status (only if network error): ", error?.response?.status);
      console.error("Network HTTP data (only if network error): ", error?.response?.data);
      console.error(error);
      sendReport(error, { name: "CartProvider", priority: "P1" });
    } finally {
      setIsLoadingCart(false);
    }
  }, [cartLocalStorageKey, discountCode, countryCode, sendReport]); // Do not track change of clientIp

  // Whenever retrieveCart changes (its dependencies change),
  // check if there's a cartId in url params and use that, overriding the existing one.
  // If there isn't one, recall retrieveCart anyway
  useEffect(() => {
    // Check if there's a cartId in url params
    if (isBrowser && params?.cartId) {
      // Set it to local storage
      localStorage.setItem(
        cartLocalStorageKey,
        params.cartId.includes("gid:")
          ? decodeURIComponent(params.cartId)
          : `gid://shopify/Cart/${params.cartId}`
      );

      // Delete the cartId from url params
      removeQueryParam("cartId");
    }

    retrieveCart();
  }, [cartLocalStorageKey, params?.cartId, retrieveCart]);

  const removeManyProductsFromCart = useCallback(
    async ({
      linesToRemove,
      customProperties = {},
      cartId = null,
      shouldOpenSideCart = true,
      discountCode: overridingDiscountCode = undefined,
    }) => {
      if (isLoadingCart) return;
      try {
        setIsLoadingCart(true);
        if (shouldOpenSideCart) {
          setIsSideCartOpen(true);
        }

        const data = {
          ...customProperties,
          cartLinesId: linesToRemove.map((line) => line.id),
          cartId: cartId || cart.cartId,
          countryCode,
          discountCode: overridingDiscountCode || discountCode,
        };
        const res = await axios.delete("/.netlify/functions/remove-product-from-cart", {
          data,
          headers: { ip_address: clientIp },
        });
        if (res.status !== 200) {
          throw new Error(
            `removeManyProductsFromCart(): Invalid response status from functions/remove-product-from-cart: ${res.status}`
          );
        }
        setCart(res.data);
        setCartId(cartLocalStorageKey, res.data.cartId, sendReport);
        linesToRemove.forEach((lineRemoved) => {
          const { price, product } = lineRemoved.variant;
          trackRemoveFromCart({
            price,
            title: product.title,
            category: product.productType,
            quantity: lineRemoved.quantity,
            lineItem: lineRemoved,
            productId: decodeProductId(lineRemoved.variant.product.id).split("?")[0],
            variantId: decodeProductId(lineRemoved.variant.id).split("?")[0],
            checkoutUrl: res?.data?.checkoutUrl || "",
            productHandle: product.handle,
          });
        });
      } catch (error) {
        console.error(error);
        sendReport(error, { name: "Provider", priority: "P1" });
      } finally {
        setIsLoadingCart(false);
      }
    },
    [
      isLoadingCart,
      cart.cartId,
      countryCode,
      discountCode,
      clientIp,
      cartLocalStorageKey,
      trackRemoveFromCart,
      sendReport,
    ]
  );

  const removeSingleProductsFromCart = useCallback(
    async ({
      lineToRemove,
      customProperties = {},
      cartId = null,
      shouldOpenSideCart = true,
      discountCode: overridingDiscountCode = undefined,
    }) =>
      removeManyProductsFromCart({
        linesToRemove: [lineToRemove],
        customProperties,
        cartId,
        shouldOpenSideCart,
        discountCode: overridingDiscountCode,
      }),
    [removeManyProductsFromCart]
  );

  /**
   * Add a list of products to the cart
   * @param {Object[]} dataProductsToAdd - Array using the same format as cart line items
   * @param {Object=} customProperties - Optional additional data sent to the lambda
   */
  const addManyProductsToCart = useCallback(
    async ({
      dataProductsToAdd,
      customProperties = {},
      cartId = null,
      shouldOpenSideCart = true,
      discountCode: overridingDiscountCode = undefined,
    }) => {
      console.log(isLoadingCart, " => isLoadingCart - CartProvider");
      if (isLoadingCart) return null;
      try {
        setIsLoadingCart(true);
        if (shouldOpenSideCart) {
          setIsSideCartOpen(true);
        }

        let productsToAdd = buildAttributes(dataProductsToAdd);
        productsToAdd = normalizeLines(dataProductsToAdd);
        productsToAdd = encryptLinesAttributes(productsToAdd);
        productsToAdd = productsToAdd.map(({ trackingProperties, ...line }) => {
          try {
            const {
              variant: { id: variantId, product },
              attributes,
            } = line;

            wrapCustomAttributes({
              productId: product.id,
              variantId,
              customAttributes: attributes,
            });
          } catch (error) {
            console.error("failed to track Add to Cart for:", line);
            sendReport(error, { name: "Provider", priority: "P1" });
          }
          return { ...line, ...trackingProperties };
        });
        console.log(productsToAdd, " => productsToAdd - CartProvider");
        const body = {
          ...customProperties,
          countryCode,
          cartId: cartId || cart.cartId,
          lines: productsToAdd,
          discountCode: overridingDiscountCode || discountCode,
        };
        console.log(body, " => body add to cart - CartProvider");
        const res = await axios.post("/.netlify/functions/add-to-cart", body, {
          headers: { ip_address: clientIp },
        });

        productsToAdd.forEach((line) => {
          const {
            variant: { price, id: variantId, product, sku },
            attributes,
            quantity,
            shouldTrackAlgolia,
            source,
          } = line;

          const isFreeGift = attributes.some(
            ({ key, value }) => key === "_free_gift" && value === "true"
          );
          if (product.handle !== "al-luxe" && !isFreeGift) {
            trackAddToCart({
              price,
              title: product.title,
              variantId: getShopifyObjectId(variantId),
              productId: getShopifyObjectId(product.id),
              sku,
              category: product.productType,
              quantity,
              currency: currencyCode,
              productHandle: product.handle,
              shouldTrackAlgolia,
              index: searchIndex,
              isPreOrder: attributes.some(({ key, value }) => key === "pre_order" && value),
              preOrderShipDate: attributes.find(({ key }) => key === "_ships_by_pre_order")?.value,
              source,
              images: product.images,
              checkoutUrl: res?.data?.checkoutUrl || "",
            });
          }
        });

        if (res.status !== 200) {
          console.error("body sent:", body);
          throw new Error(
            `addManyProductsToCart(): Invalid response status from functions/add-to-cart: ${res.status}`
          );
        }
        setCart(res.data);
        setCartId(cartLocalStorageKey, res.data.cartId, sendReport);

        // Trigger ATC events exponea
        try {
          trackAddToCartExponea(prepareCartForAddToCartExponea(res.data));
        } catch (error) {
          console.log(error);
          sendReport(error, { name: "CartProvider", priority: "P1" });
        }
        return res.data;
      } catch (error) {
        console.error(error);
        sendReport(error, { name: "CartProvider", priority: "P1" });
        return null;
      } finally {
        setIsLoadingCart(false);
      }
    },
    [
      isLoadingCart,
      countryCode,
      cart,
      discountCode,
      clientIp,
      cartLocalStorageKey,
      trackAddToCart,
      currencyCode,
      searchIndex,
      wrapCustomAttributes,
      sendReport,
      trackAddToCartExponea,
    ]
  );

  const addSingleProductToCart = useCallback(
    async ({ dataProductToAdd, customProperties = {}, cartId = null, shouldOpenSideCart = true }) =>
      addManyProductsToCart({
        dataProductsToAdd: [dataProductToAdd],
        customProperties,
        cartId,
        shouldOpenSideCart,
      }),
    [addManyProductsToCart]
  );

  const resetCart = useCallback(() => {
    const shopifyCheckoutIdStorageKey = getShopifyCartIdStorageKey(countryCode);
    localStorage.removeItem(shopifyCheckoutIdStorageKey);
    setCart(defaultCartObject);
  }, [countryCode]);

  const itemsInCart = useMemo(
    () =>
      cart.lines.reduce((prev, p) => {
        if (
          p?.variant?.product?.handle !== HandleType.ORDER_PROTECTION &&
          p?.variant?.product?.handle !== HandleType.SUBSCRIPTION // TO update on pre-prod with proper product handle
        ) {
          return prev + p.quantity;
        }
        return prev;
      }, 0),
    [cart]
  );

  const updateCartAttributes = useCallback(
    async ({ customAttributes }) => {
      if (isLoadingCart || !cart?.cartId) return;
      try {
        setIsLoadingCart(true);
        const res = await axios.post("/.netlify/functions/update-cart-attributes", {
          cartId: cart.cartId,
          countryCode,
          attributes: customAttributes,
        });
        if (res.status !== 200) {
          throw new Error(
            `updateCartAttributes(): Invalid response status from functions/update-cart-attributes: ${res.status}`
          );
        }
        setCart(res.data);
        setCartId(cartLocalStorageKey, res.data.cartId, sendReport);
      } catch (error) {
        console.error(error);
        sendReport(error, { name: "Provider", priority: "P1" });
      } finally {
        setIsLoadingCart(false);
      }
    },
    [isLoadingCart, cart, countryCode, cartLocalStorageKey, sendReport]
  );

  const discountStatus = useMemo(() => {
    if (!quantityPercentDiscount) {
      return null;
    }

    const totalEligibleItems = cart?.lines.reduce((total, line) => {
      const hasExcludedTag = line.variant.product.tags.some((tag) =>
        EXCLUDE_ITEMS_TAGS.includes(tag)
      );
      return hasExcludedTag ? total : total + line.quantity;
    }, 0);

    const getValuesDiscount = (d, nbItems) => {
      // If nbItems is 0 and the first threshold in d is greater than 1, return 0
      if (nbItems + 1 < d[0][0]) {
        return {
          current: null,
          next: null,
        };
      }

      // Find next discount to be applied (outside Luxe Extra discount)
      const next = d.find(([threshold]) => nbItems + 1 <= threshold) || d[d.length - 1];

      // Find current applied discount
      let current = null;
      d.forEach((discount, i) => {
        if (i + 1 === d.length && nbItems >= discount[0]) {
          // last element
          current = discount;
        } else if (nbItems === discount[0]) {
          current = discount;
        }
      });

      return {
        next: next === current ? null : next,
        current,
      };
    };

    return getValuesDiscount(quantityPercentDiscount.discounts, totalEligibleItems);
  }, [cart?.lines, quantityPercentDiscount]);

  const cartData = useMemo(
    () => ({
      cart,
      isLoadingCart,
      addManyProductsToCart,
      addSingleProductToCart,
      removeManyProductsFromCart,
      removeSingleProductsFromCart,
      getShopifyCartIdStorageKey,
      resetCart,
      itemsInCart,
      isSideCartOpen,
      setIsSideCartOpen,
      toggleCart,
      discountStatus,
      updateCartAttributes,
    }),
    [
      addManyProductsToCart,
      addSingleProductToCart,
      cart,
      isLoadingCart,
      removeManyProductsFromCart,
      removeSingleProductsFromCart,
      resetCart,
      itemsInCart,
      isSideCartOpen,
      setIsSideCartOpen,
      toggleCart,
      discountStatus,
      updateCartAttributes,
    ]
  );

  useEffect(() => {
    if (isBrowser) {
      // Blotout cart recovery sync
      const handleCartUpdate = (event) => {
        const newCart = event.detail;
        setCart(event.detail);
        newCart.lines.forEach((line) => {
          const {
            variant: { price, id: variantId, product, sku, image },
            attributes,
            quantity,
          } = line;

          if (product.handle !== "al-luxe") {
            trackAddToCart({
              price: Number(price.amount),
              title: product.title,
              variantId: getShopifyObjectId(variantId),
              productId: getShopifyObjectId(product.id),
              sku,
              category: product.productType,
              quantity,
              currency: price.currencyCode,
              productHandle: product.handle,
              shouldTrackAlgolia: false,
              index: null,
              isPreOrder: attributes.some(({ key, value }) => key === "pre_order" && value),
              preOrderShipDate: attributes.find(({ key }) => key === "_ships_by_pre_order")?.value,
              source: null,
              images: [image],
              checkoutUrl: newCart.checkoutUrl || "",
            });
          }
        });
        trackAddToCartExponea(prepareCartForAddToCartExponea(newCart));
      };
      window.addEventListener(CART_UPDATE_EVENT_NAME, handleCartUpdate);
      return () => {
        window.removeEventListener(CART_UPDATE_EVENT_NAME, handleCartUpdate);
      };
    }
  }, [trackAddToCart, trackAddToCartExponea]);

  return <CartContext.Provider value={cartData}>{children}</CartContext.Provider>;
}

CartProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CartProvider;
