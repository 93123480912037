import {
  createCookie,
  deleteCookie,
  loadScriptHead,
  isBrowser,
  hashEmail,
  getAllUrlParams,
  getCookie,
} from "./src/context/helpers";
import { getPageViewTrackings } from "./src/context/Tracking";

export const onInitialClientRender = () => {
  window.__isHydrated = true;

  // AppLovin - Pixel implementation
  // Check if the `_axwrt` cookie exists
  const axwrtValue = getCookie("_axwrt");

  if (axwrtValue) {
    // Set the `axwrt` cookie if it doesn't already exist
    if (!getCookie("axwrt")) {
      document.cookie = `axwrt=${axwrtValue}; Expires=${new Date(
        Date.now() + 365 * 24 * 60 * 60 * 1000 // 1 year expiration
      ).toUTCString()}; Domain=.analuisa.com; Path=/; SameSite=Lax`;
      console.log("axwrt cookie has been set");
    }
  }
};

export const onClientEntry = () => {
  window.ElevarDataLayer = window.ElevarDataLayer ?? [];
};

export const onPreRouteUpdate = ({ location }) => {
  const paramsURL = getAllUrlParams(location?.href);
  if (paramsURL?.irclickid) {
    // Store irclickid in cookies for 30 days (+ update cookie)
    createCookie("_irclickid", paramsURL?.irclickid, 30);
  }
};

/**
 * Do not run actionFun until conditionFun returns true
 * @param {*} actionFun
 * @param {*} conditionFun
 */
function runUntil(actionFun, conditionFun) {
  if (conditionFun()) {
    actionFun();
  } else {
    setTimeout(() => {
      runUntil(actionFun, conditionFun);
    }, 100);
  }
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  // This is needed after we switch to Gatsby Script to load script.
  // We need to make sure everything is ready before calling these
  // analytics functions.

  runUntil(
    () => {
      if (window.ElevarInvalidateContext) {
        window.ElevarInvalidateContext?.();
      }
    },
    () => window.ElevarInvalidateContext
  );

  getPageViewTrackings().forEach((trackingPageView) =>
    runUntil(
      () => {},
      () => trackingPageView()
    )
  );

  runUntil(
    () => {
      // Reload Impact script for each page change
      loadScriptHead("https://utt.impactcdn.com/A3670505-08a4-4003-a105-fc58ad27da3c1.js").then(
        () => {
          const exponeaEmail = isBrowser && window.exponea?.email_id;
          const email = hashEmail(exponeaEmail);
          const data = email
            ? {
                customerId: email,
                customerEmail: email,
              }
            : {
                customerId: "",
                customerEmail: "",
              };
          window.ire("identify", data);
        }
      );
    },
    () => window?.exponea
  );

  if (
    ((!prevLocation || prevLocation?.pathname?.includes("/collections/")) &&
      !location?.pathname?.includes("/products/")) ||
    location?.search?.includes("?origin_content=")
  ) {
    // Remove cookies path
    deleteCookie("bread_url");
    deleteCookie("bread_title");
  }

  if (
    (location.pathname.includes("/products/") || location.pathname.includes("/p/")) &&
    !window.vtoPdpScriptLoaded
  ) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src =
      "https://cdn.tangiblee.com/integration/5.0/managed/www.analuisa.com/revision_1/variation_original/tangiblee-bundle.min.js";
    document.head.appendChild(script);
    window.vtoPdpScriptLoaded = true;
  }

  if (location.pathname.includes("/charm-builder") && !window.vtoCharmScriptLoaded) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.src =
      "https://cdn.tangiblee.com/integration/5.0/managed/www.analuisa.com/revision_2/variation_original/tangiblee-bundle.min.js";
    document.head.appendChild(script);
    window.vtoCharmScriptLoaded = true;
  }
};
